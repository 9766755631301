import * as Tone from "tone"
import store from "../state/createStore"
import colors from "../data/colors.json"

const changeColor = () => {
  return Math.floor(Math.random() * Math.floor(colors.length))
}

async function triggerNote(note, now) {
  window.sampler.triggerAttackRelease(
    note.name,
    note.duration,
    note.time + now,
    note.velocity
  )
  setTimeout(() => {
    if (!window.epilepsy) {
      store.dispatch({
        type: "color",
        data: { color: changeColor(note.name), note: note.name },
      })
    }
  }, note.time * 1000)
}

export async function playThatFunkyMusicWhiteBoy(newTrack, maxLen) {
  const now = Tone.now()
  let lastTime = 0
  for (var i = 0; i < maxLen; i++) {
    if (newTrack[i]) {
      const note = newTrack[i]
      triggerNote(note, now)
      if (lastTime < note.time * 1000) {
        lastTime = note.time * 1000
      }
    }
  }
  if (lastTime !== 0) {
    setTimeout(() => {
      store.dispatch({ type: "stop" })
    }, lastTime + 500)
  }
}

export async function assembleSampler() {
  window.sampler = new Tone.Sampler({
    urls: {
      C5:
        "https://firebasestorage.googleapis.com/v0/b/jukebox-3bf54.appspot.com/o/meghan%2Fanothergo%2FC5.mp3?alt=media&token=581d5936-e84a-4923-8c53-3bb187021b58",
      C4: "https://firebasestorage.googleapis.com/v0/b/jukebox-3bf54.appspot.com/o/yannis%2FC4-la.mp3?alt=media&token=df0a7319-34ef-4c53-a7da-5ebe4cd68f15"
    },
    release: 1,
  }).toDestination()

  await Tone.loaded()
  console.log("sampler ready")
  store.dispatch({ type: "server/sampler-ready" })
}

export function triggerRelease(note) {
  window.sampler.triggerRelease(note)
}

export function triggerAttack(note) {
  window.sampler.triggerAttack(note)
}
