import store from "../state/createStore";
import {assembleSampler} from "./Sampler"
import * as Tone from "tone"

export async function intiateTone(){
    console.log("Permissions Granted")
    await Tone.start();
    assembleSampler()
    store.dispatch({type: "server/subbed"});
} 

