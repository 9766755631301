import { createStore, applyMiddleware } from "redux"
import createSocketIoMiddleware from "redux-socket.io"
import io from "socket.io-client"
import isDevelopment from "../utils/isDevelopment"
import { intiateTone } from "../music/Tone"
import {
  playThatFunkyMusicWhiteBoy,
  triggerRelease,
  triggerAttack,
} from "../music/Sampler"
import { Sampler } from "tone"
let socket = io(
  isDevelopment()
    ? "http://localhost:3000"
    : "https://sld-jukebox.herokuapp.com"
)
let socketIoMiddleware = createSocketIoMiddleware(socket, "server/")

function reducer(
  state = {
    permission: false,
    count: 0,
    audioReadyCount: 0,
    track: [],
    currentNote: "Now Playing",
    color: 4,
    epilepsy: false,
    connected: false,
    part: false,
    retrievedAudio: false,
    isPlaying: false,
    maxLen: 0,
    currentTriggers: [],
  },
  action
) {
  switch (action.type) {
    case "connected": {
      return Object.assign({}, { ...state, connected: true })
    }
    case "music": {
      //window.socket.emit("musicReceived")
      return Object.assign(
        {},
        {
          ...state,
          part: action.data.part,
          track: action.data.track.notes,
          maxLen: action.data.maxLen,
        }
      )
    }
    case "server/sampler-ready": {
      return Object.assign({}, { ...state, retrievedAudio: true })
    }
    case "trigger": {
      triggerAttack(action.data)

      return Object.assign(
        {},
        {
          ...state,
          currentTriggers: [...state.currentTriggers, action.data],
          isPlaying: true,
          currentNote: action.data,
        }
      )
    }
    case "release": {
      triggerRelease(action.data)
      let newTriggers = state.currentTriggers.filter(
        item => item !== action.data
      )
      return Object.assign(
        {},
        {
          ...state,
          currentTriggers: newTriggers,
          isPlaying: newTriggers.length === 0 ? false : true,
        }
      )
    }
    case "color": {
      return Object.assign(
        {},
        {
          ...state,
          color: action.data.color,
          currentNote: action.data.note,
        }
      )
    }
    case "permissions": {
      intiateTone()
      //  assembleSampler()
      return Object.assign(
        {},
        {
          ...state,
          permission: true,
        }
      )
    }
    case "userCount": {
      return Object.assign(
        {},
        {
          ...state,
          count: action.data.total,
          audioReadyCount: action.data.audioReady,
        }
      )
    }
    case "sampler-attack": {
      triggerAttack(action.data)
    }
    case "sampler-release": {
      triggerRelease(action.data)
    }
    case "start": {
      const startTime =
        new Date(action.data.time).getTime() - new Date().getTime()
      setTimeout(() => {
        playThatFunkyMusicWhiteBoy(state.track, state.maxLen)
      }, startTime)

      return Object.assign({}, { ...state, isPlaying: true })
    }
    case "stop": {
      return Object.assign({}, { ...state, isPlaying: false })
    }
    default:
      return state
  }
}
let store = applyMiddleware(socketIoMiddleware)(createStore)(reducer)
store.subscribe(() => {
  if (isDevelopment()) {
    console.log("new client state", store.getState())
  }
})

export default store
